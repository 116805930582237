import React from "react"

import Layout from '../components/layout'

import { TitlePage } from '../components/common/titlePage'
import { Gallery } from '../components/gallery'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'


const BydgoszczGaleria = () => {

  const data = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "dermapen-4-efekty-zabiegow.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            
          )
        }
      }
    }
`)

  return (
    <Layout bydgoszcz>
      <Seo
        title="Bydgoszcz galeria"
        description="Galeria przed i po zabiegach w BodyMed Group Bydgoszcz."
        url="https://bodymedgroup.pl/bydgoszcz-galeria"
      />
      <TitlePage
        title='Efekty zabiegów' 
        text='Wahasz się czy zabieg, który Cię interesuje przynosi efekty? Przejrzyj naszą galerię "przed i po" i przekonaj się sama!' 
        imageTitle={data.file.childImageSharp.gatsbyImageData}
        titleSeoImage="Programmers working in the office" 
        altSeoImage="Programmers working in the office"
      />
      <Gallery />
    </Layout>
  )
}

export default BydgoszczGaleria
